import { type ReactNode } from 'react';

import { getStaticAssetPath } from '../../../utils/assets';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../../ConfirmCancelModalContext';
import { GameCoverClip } from '../../GamePackCover/GamePackCoverPres';
import { ProvidersList } from '../../ProvidersList';

function CustomGamePackProvider(props: { children: ReactNode }) {
  const providers = [<ConfirmCancelModalProvider />];

  return (
    <ProvidersList providers={providers}>
      {props.children}
      <ConfirmCancelModalRoot />
      <GameCoverClip id='game-cover-clip' />
    </ProvidersList>
  );
}

export function CustomGamePackLayout(props: {
  children?: ReactNode;
  useBackground?: boolean;
}) {
  return (
    <CustomGamePackProvider>
      <div
        className='w-screen h-screen flex flex-col'
        style={
          props.useBackground
            ? {
                background: `url('${getStaticAssetPath(
                  'images/ugc/background.png'
                )}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }
            : undefined
        }
      >
        {props.children}
      </div>
    </CustomGamePackProvider>
  );
}

export function CustomGamePackContainer(props: {
  left?: ReactNode;
  right?: ReactNode;
  children?: ReactNode;
}) {
  return (
    <section className='w-full flex flex-1 relative'>
      <aside className='w-55 h-full absolute left-0 top-0'>{props.left}</aside>
      <main className='mx-55 absolute inset-0'>{props.children}</main>
      <aside className='w-55 h-full absolute right-0 top-0'>
        {props.right}
      </aside>
    </section>
  );
}
